<template>
  <div>
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-item label="Bulan">
          <a-select size="large" class="w-100" :value="dataPeriode.bulan" @change="(e) => handleChange(e, 'bulan')">
            <a-select-option v-for="(item, index) in bulan" :key="index" :value="item.nama">
              {{item.nama}}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item label="Tahun">
          <a-select size="large" class="w-100" :value="dataPeriode.tahun" @change="(e) => handleChange(e, 'tahun')">
            <a-select-option v-for="(item, index) in tahun" :key="index" :value="item.nama">
              {{item.nama}}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item label="Tanggal Awal Periode">
          <a-date-picker
            :value="dataPeriode.tanggal_awal"
            :disabled-date="disabledStartDate"
            format="YYYY-MM-DD"
            @openChange="handleStartOpenChange"
            @change="(e) => handleChange(e, 'tanggal_awal')"
            class="w-100"
            size="large"
          />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item label="Tanggal Akhir Periode">
          <a-date-picker
            :value="dataPeriode.tanggal_akhir"
            :disabled-date="disabledEndDate"
            format="YYYY-MM-DD"
            :open="endOpen"
            @openChange="handleEndOpenChange"
            @change="(e) => handleChange(e, 'tanggal_akhir')"
            class="w-100"
            size="large"
          />
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { bulan, tahun } from '@/helpers/pertanggalanIndonesia'
export default {
  props: {
    dataPeriode: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      bulan,
      tahun,
      endOpen: false,
    }
  },
  methods: {
    disabledStartDate(startValue) {
      const endValue = this.dataPeriode.tanggal_akhir
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate(endValue) {
      const startValue = this.dataPeriode.tanggal_awal
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() >= endValue.valueOf()
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    handleChange(value, column) {
      const payload = { value, column }
      this.$emit('handle-change', payload)
    },
  },
}
</script>

<style>

</style>
