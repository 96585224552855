const bulan = [
  {
    nama: 'Januari',
  },
  {
    nama: 'Februari',
  },
  {
    nama: 'Maret',
  },
  {
    nama: 'April',
  },
  {
    nama: 'Mei',
  },
  {
    nama: 'Juni',
  },
  {
    nama: 'Juli',
  },
  {
    nama: 'Agustus',
  },
  {
    nama: 'September',
  },
  {
    nama: 'Oktober',
  },
  {
    nama: 'November',
  },
  {
    nama: 'Desember',
  },
]

const tahun = []
for (let i = 10; i <= 30; i++) {
  tahun.push({
    nama: `20${i}`,
  })
}

module.exports = {
  bulan,
  tahun,
}
